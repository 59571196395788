import * as THREE from "three";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import API from "@/api/API";
import { BLACK, VERTICAL, HORIZONTAL, RENDERING_ORDER } from "../constants";
import { isPanelSmall } from "@/utils/panel-size.js";

export const choosePanelAdditionType = function (type) {
  this.panelAdditionType = type;
  this.panelStep = 1;
};

export const choosePanelType = function (type) {
  this.panelType = type;
  if (!this.chosenPanel) {
    return;
  }
  if (this.panelAdditionType === 0) {
    this.panelStep = 2;
  } else {
    this.panelSetupConfirmed = true;
    this.addPanel();
  }
};

export const confirmPanelSetup = function () {
  this.selectedArea.panelSpacing = this.panelSpacing;
  this.panelSetupConfirmed = true;
  this.populateArea(this.selectedArea);
};

export const cleanPanelSetup = function () {
  this.panelAdditionType = null;
  this.panelType = null;
  this.panelStep = 0;
  this.panelSpacing = null;
  this.panelSetupConfirmed = false;
};

export const getPanelArea = function (area) {
  let totalArea = 0;
  for (let panel of area.panels) {
    totalArea += panel.size.width * panel.size.height;
  }
  return totalArea.toFixed(2);
};

export const enableAddPanelsMode = function () {
  if (this.editPanelMode) this.disablePanelEditMode();
  this.toggleActive(3);
};

export const enablePanelEditMode = function () {
  this.toggleActive(5);
};

export const disablePanelEditMode = function () {
  this.toggleActive(2);
};

export const closeEditMode = function () {
  if (this.editPanelMode) this.disablePanelEditMode();
};

export const addPanel = async function () {
  const areaObject = this.selectedArea;
  const points = areaObject.points.map((point) => point.position);

  const point1 = new THREE.Vector3(
    areaObject.points[0].position.x,
    areaObject.points[0].position.y,
    areaObject.points[0].position.z
  );
  const point2 = new THREE.Vector3(
    areaObject.points[1].position.x,
    areaObject.points[1].position.y,
    areaObject.points[1].position.z
  );
  const point3 = new THREE.Vector3(
    areaObject.points[2].position.x,
    areaObject.points[2].position.y,
    areaObject.points[2].position.z
  );

  const longestEdge = this.getLongestEdge(points);

  const normal = new THREE.Vector3();
  normal
    .crossVectors(
      point2.clone().sub(point1.clone()),
      point3.clone().sub(point1)
    )
    .normalize();

  const inPlaneVec = new THREE.Vector3()
    .crossVectors(normal, longestEdge)
    .normalize();

  const shiftedCenterPoint = this.getShiftedCenterPoint(
    areaObject.points,
    inPlaneVec,
    normal
  );

  let toCamera = new THREE.Vector3()
    .subVectors(this.camera.position, shiftedCenterPoint)
    .normalize();

  if (normal.dot(toCamera) < 0) {
    normal.negate();
  }

  // const plane = this.createPanel(areaObject);
  const plane = this.createPanelMesh(areaObject);
  plane.material.depthTest = false;
  plane.renderOrder = RENDERING_ORDER.PANEL + this.panelCounter;
  plane.up.copy(inPlaneVec);

  const offsetDistance = 0.05;
  const offset = normal.clone().multiplyScalar(offsetDistance);
  const offsetPosition = shiftedCenterPoint.clone().add(offset);

  plane.position.copy(offsetPosition);

  plane.lookAt(offsetPosition.clone().add(normal));

  const geometry = plane.geometry.clone();
  plane.updateMatrix();
  plane.updateWorldMatrix();
  geometry.applyMatrix4(plane.matrixWorld);

  const flatPlaneVertices = geometry.attributes.position.array;
  const planeVertices = this.flatArrayToVectors3D(flatPlaneVertices);

  const flatAreaVertices = areaObject.plane.geometry.attributes.position.array;
  const areaVertices = this.flatArrayToVectors3D(flatAreaVertices);

  if (
    this.isRectangleWithinPoints(
      planeVertices,
      areaVertices,
      areaObject.indices
    )
  ) {
    const icon = this.createTrashIcon();
    plane.add(icon);
    this.scene.add(plane);

    const planePanel = {
      plane: plane,
      icon: icon,
      size: {
        width: this.chosenPanel.size.width,
        height: this.chosenPanel.size.height,
      },
      panelId: this.chosenPanel.id,
      orientation: this.chosenPanel.orientation,
    };

    if (!this.anonymousUser) {
      try {
        const { data } = await this.createPanelObject(
          areaObject.id,
          {
            x: plane.position.x,
            y: plane.position.y,
            z: plane.position.z,
          },
          this.chosenPanel.id,
          this.chosenPanel.orientation,
          plane.renderOrder
        );
        planePanel.id = data;
      } catch (e) {
        console.log(e);
      }
    }

    areaObject.panels.push(planePanel);

    this.undoStack.push({
      action: "ADD_PANEL",
      panel: planePanel,
      area: areaObject,
    });
    this.resetRedoStack();
  } else {
    let index = 0;
    do {
      for (let i = 0; i < 100; i++) {
        const shiftedPoint = this.getShiftedPoint(
          areaObject.points,
          inPlaneVec,
          normal,
          index,
          i
        );

        toCamera = new THREE.Vector3()
          .subVectors(this.camera.position, shiftedPoint)
          .normalize();

        if (normal.dot(toCamera) < 0) {
          normal.negate();
        }

        const offsetDistance = 0.05;
        const offset = normal.clone().multiplyScalar(offsetDistance);
        const offsetPosition = shiftedPoint.clone().add(offset);

        plane.position.copy(offsetPosition);
        plane.up.copy(inPlaneVec);
        plane.lookAt(offsetPosition.clone().add(normal));

        const geometry = plane.geometry.clone();
        plane.updateMatrix();
        plane.updateWorldMatrix();
        geometry.applyMatrix4(plane.matrixWorld);

        const flatPlaneVertices = geometry.attributes.position.array;
        const planeVertices = this.flatArrayToVectors3D(flatPlaneVertices);

        const flatAreaVertices =
          areaObject.plane.geometry.attributes.position.array;
        const areaVertices = this.flatArrayToVectors3D(flatAreaVertices);

        if (
          this.isRectangleWithinPoints(
            planeVertices,
            areaVertices,
            areaObject.indices
          )
        ) {
          const icon = this.createTrashIcon();
          plane.add(icon);
          this.scene.add(plane);

          const planePanel = {
            plane: plane,
            icon: icon,
            size: {
              width: this.chosenPanel.size.width,
              height: this.chosenPanel.size.height,
            },
            panelId: this.chosenPanel.id,
            orientation: this.chosenPanel.orientation,
          };

          if (!this.anonymousUser) {
            try {
              const { data } = await this.createPanelObject(
                areaObject.id,
                {
                  x: plane.position.x,
                  y: plane.position.y,
                  z: plane.position.z,
                },
                this.chosenPanel.id,
                this.chosenPanel.orientation,
                plane.renderOrder
              );
              planePanel.id = data;
            } catch (e) {
              console.log(e);
            }
          }

          areaObject.panels.push(planePanel);

          this.undoStack.push({
            action: "ADD_PANEL",
            panel: planePanel,
            area: areaObject,
          });
          this.resetRedoStack();

          index = points.length + 1;
          break;
        }
      }
      index = index + 1;
    } while (
      !this.isRectangleWithinPoints(
        planeVertices,
        areaVertices,
        areaObject.indices
      ) &&
      index < points.length
    );
  }

  this.hideAreaPoints(areaObject);
  this.disablePointDragMode();
  this.isEditButtonDisplayed = this.selectedArea.panels.length > 0;
  this.toggleActive(2);
  this.panelCounter++;
};

export const removePanels = async function (panelsToRemove, area) {
  const panelPromises = [];
  for (let panelToRemove of panelsToRemove) {
    const panelObject = this.scene.getObjectById(panelToRemove.plane.id);
    area.panels = area.panels.filter(
      (panel) => panel.plane.id !== panelToRemove.plane.id
    );
    panelObject.visible = false;

    panelPromises.push(panelToRemove.id);
  }

  const panelArrays = chunkArray(panelPromises, 50);
  const panelArrayPromises = panelArrays.map((array) =>
    this.deleteBulkPanelObjects(array)
  );
  Promise.all(panelArrayPromises);

  this.undoStack.push({
    action: "DELETE_PANELS",
    panels: panelsToRemove,
    area: this.selectedArea,
  });
  this.resetRedoStack();
};

export const hidePanels = async function (panelsToRemove, area) {
  const panelPromises = [];
  for (let panelToRemove of panelsToRemove) {
    const panelObject = this.scene.getObjectById(panelToRemove.plane.id);
    area.panels = area.panels.filter(
      (panel) => panel.plane.id !== panelToRemove.plane.id
    );
    panelObject.visible = false;
    panelPromises.push(this.deletePanelObject(panelToRemove.id));
  }

  Promise.all(panelPromises);
};

export const deleteSelectedPanel = function (event) {
  const key = event.key;
  if (key === "Delete" || key === "Backspace") {
    const selectedPanels = this.selectedArea.panels.filter(
      (panel) => panel.selected
    );
    if (selectedPanels.length > 0)
      this.removePanels(selectedPanels, this.selectedArea);
  }
};

export const selectPanel = function (event) {
  event.preventDefault();

  if (event.target.tagName !== "CANVAS") {
    this.unselectPanels();
    return;
  }

  if (this.isShiftDown || this.isAltDown) return;

  if (this.dragOn || this.startSelection) return;

  if (this.frustumSelection) {
    this.frustumSelection = false;
    return;
  }

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObjects(
    this.selectedArea.panels.map((panel) => panel.plane)
  );
  if (intersects.length < 1) {
    return;
  }

  let panelToExpand = intersects
    .map((panel) => panel.object)
    .reduce((prev, current) => {
      return prev.renderOrder > current.renderOrder ? prev : current;
    });
  const clickedPanel = this.selectedArea.panels.find(
    (panel) => panel.plane.id === panelToExpand.id
  );

  if (clickedPanel.selected && this.detectPanelDeletion(clickedPanel)) {
    this.removePanels([clickedPanel], this.selectedArea);
  }

  if (!event.shiftKey)
    this.selectedArea.panels.forEach((panel) => {
      panel.selected = false;
      if (panel.icon?.visible) panel.icon.visible = false;
    });
  clickedPanel.selected = !clickedPanel.selected;
  clickedPanel.icon.visible = clickedPanel.selected;

  this.addDragControlsOnPanels();
};

export const unselectPanels = function () {
  if (this.selectedArea.panelsDragControls) this.removeDragControlsFromPanels();
  this.selectedArea.panels.forEach((panel) => {
    panel.selected = false;
    if (panel.icon?.visible) panel.icon.visible = false;
  });
};

export const populateArea = async function (area, useNegative = true) {
  const points = area.points.map((point) => point.position);

  const point1 = new THREE.Vector3(
    area.points[0].position.x,
    area.points[0].position.y,
    area.points[0].position.z
  );
  const point2 = new THREE.Vector3(
    area.points[1].position.x,
    area.points[1].position.y,
    area.points[1].position.z
  );
  const point3 = new THREE.Vector3(
    area.points[2].position.x,
    area.points[2].position.y,
    area.points[2].position.z
  );

  const longestEdge = this.getLongestEdge(points);

  const normal = new THREE.Vector3();
  normal
    .crossVectors(
      point2.clone().sub(point1.clone()),
      point3.clone().sub(point1)
    )
    .normalize();

  const rightMostPoint = this.getRightMostPoint(points);
  const leftMostPoint = this.getLeftMostPoint(points);
  const topMostPoint = this.getTopMostPoint(points);
  const bottomMostPoint = this.getBottomMostPoint(points);

  const rectWidth = this.chosenPanel.size.width;
  const rectHeight = this.chosenPanel.size.height;
  const rectSpacing = this.panelSpacing / 100;

  const planeWidth = leftMostPoint.distanceTo(rightMostPoint);
  const planeHeight = bottomMostPoint.distanceTo(topMostPoint);
  const numRectsX = Math.ceil(planeWidth / (rectWidth + rectSpacing));
  const numRectsY = Math.ceil(planeHeight / (rectHeight + rectSpacing));

  // To move the rectangle along the plane itself, you can use the cross product of the normal vector and the vector from point1 to point2 to get a vector perpendicular to the normal that lies in the plane.
  let inPlaneVec = new THREE.Vector3()
    .crossVectors(normal, longestEdge)
    .normalize();

  const projectedTopLeftPoint = leftMostPoint
    .clone()
    .add(
      inPlaneVec
        .clone()
        .multiplyScalar(
          bottomMostPoint.clone().sub(leftMostPoint).dot(inPlaneVec)
        )
    );

  const projectedTopRightPoint = rightMostPoint
    .clone()
    .add(
      inPlaneVec
        .clone()
        .multiplyScalar(
          bottomMostPoint.clone().sub(rightMostPoint).dot(inPlaneVec)
        )
    );

  const startPos = projectedTopLeftPoint
    .clone()
    .add(normal.clone().multiplyScalar(rectHeight / 2))
    .add(normal.clone().multiplyScalar(rectSpacing / 2));

  let verticalScalar = useNegative
    ? -(rectHeight + rectSpacing)
    : rectHeight + rectSpacing;
  let verticalSpacing = useNegative ? -rectSpacing : rectSpacing;

  let addedPanels = 0;

  const addedPanelsArray = [];

  // const panelId =
  //   this.chosenPanel.size.width > this.chosenPanel.size.height
  //     ? `${this.chosenPanel.id}_h`
  //     : this.chosenPanel.id;

  // let instancedMesh = area.panelMap.get(panelId);
  // if (!instancedMesh) {
  //   instancedMesh = this.createPanelInstance(area, panelId);
  // }

  const panelPromises = [];
  const panelsInPromises = [];

  let index = 0;
  for (let i = 0; i < numRectsY; i++) {
    for (let j = 0; j < numRectsX + 1; j++) {
      const pos = startPos
        .clone()
        // move the panel vertically
        .add(
          inPlaneVec
            .clone()
            .multiplyScalar(verticalScalar * i + verticalSpacing)
        )
        // move the panel horizontally from topLeft Point to topRight point
        .add(
          projectedTopRightPoint
            .clone()
            .sub(projectedTopLeftPoint)
            .normalize()
            .multiplyScalar((rectWidth + rectSpacing) * j)
        )
        // ensure the panel is centered along the horizontal direction
        .sub(
          projectedTopRightPoint
            .clone()
            .sub(projectedTopLeftPoint)
            .normalize()
            .multiplyScalar(rectWidth / 2)
        )
        // ensure the panel is sits on the plane, not just parallel to it
        .sub(normal.clone().multiplyScalar(rectHeight / 2));

      let rectMesh = this.createTempPanel();

      rectMesh.position.copy(pos);
      rectMesh.up.copy(inPlaneVec);
      rectMesh.lookAt(pos.clone().add(normal));

      const geometry = rectMesh.geometry.clone();
      rectMesh.updateMatrix();
      rectMesh.updateWorldMatrix();
      geometry.applyMatrix4(rectMesh.matrixWorld);

      const flatPlaneVertices = geometry.attributes.position.array;
      const planeVertices = this.flatArrayToVectors3D(flatPlaneVertices);

      const areaVertices = area.points.map((point) => point.position);

      if (
        this.isRectangleWithinPoints(planeVertices, areaVertices, area.indices)
      ) {
        const normalCopy = normal.clone();
        const offset = normal.clone().multiplyScalar(0.05);
        const toCamera = new THREE.Vector3()
          .subVectors(this.camera.position, pos)
          .normalize();
        if (normalCopy.dot(toCamera) < 0) {
          pos.sub(offset);
          normalCopy.negate();
        } else {
          pos.add(offset);
        }
        // rectMesh = this.useInstancedPanel(area, panelId);
        rectMesh = this.createPanelMesh(area);
        rectMesh.position.copy(pos);
        rectMesh.up.copy(inPlaneVec);
        rectMesh.material.depthTest = false;
        rectMesh.renderOrder = RENDERING_ORDER.PANEL;
        rectMesh.lookAt(pos.clone().add(normalCopy));

        const icon = this.createTrashIcon(false);

        const panelPlane = {
          plane: rectMesh,
          size: {
            width: this.chosenPanel.size.width,
            height: this.chosenPanel.size.height,
          },
          icon: icon,
          panelId: this.chosenPanel.id,
          orientation: this.chosenPanel.orientation,
        };

        area.panels.push(panelPlane);

        rectMesh.add(icon);
        this.scene.add(rectMesh);

        if (!this.anonymousUser && !this.sample) {
          panelPromises.push({
            type: "PANEL",
            projectId: Number(this.projectId),
            areaId: area.id,
            position: {
              x: rectMesh.position.x,
              y: rectMesh.position.y,
              z: rectMesh.position.z,
            },
            panelId: this.chosenPanel.id,
            orientation: this.chosenPanel.orientation,
            renderOrder: rectMesh.renderOrder,
          });

          panelsInPromises.push(panelPlane);
        }

        addedPanels++;
        addedPanelsArray.push(panelPlane);
      }
      index++;
    }
  }

  if (addedPanels === 0 && useNegative) {
    this.populateArea(area, false);
    return;
  }

  const panelArrays = chunkArray(panelPromises, 50);
  const panelInArrays = chunkArray(panelsInPromises, 50);
  const panelArrayPromises = panelArrays.map((array) =>
    this.createBulkPanelObjects(array)
  );
  Promise.all(panelArrayPromises).then((responses) => {
    responses.forEach((response, index) => {
      const { data } = response;
      const arrayOfPanels = panelInArrays[index];
      for (let i = 0; i < data.length; i++) {
        arrayOfPanels[i].id = data[i];
      }
    });
  });

  if (addedPanelsArray.length > 0) {
    this.undoStack.push({
      action: "BULK_ADD_PANELS",
      panels: addedPanelsArray,
      area: area,
    });
    this.resetRedoStack();
  }

  this.hideAreaPoints(area);
  this.disablePointDragMode();
  this.isEditButtonDisplayed = this.selectedArea.panels.length > 0;
  this.toggleActive(2);
};

export const getLongestEdge = function (points) {
  let longestEdge = null;
  let longestEdgeLength = 0;
  for (let i = 0; i < points.length; i++) {
    const edge = new THREE.Vector3().subVectors(
      points[i],
      points[(i + 1) % points.length]
    );
    const edgeLength = edge.length();
    if (edgeLength > longestEdgeLength) {
      longestEdgeLength = edgeLength;
      longestEdge = edge;
    }
  }
  return longestEdge;
};

export const createTrashIcon = function (addCounter = true) {
  const iconMaterial = new THREE.MeshBasicMaterial({
    map: this.closeTexture,
    transparent: true,
  });

  const trashDimension =
    this.trashSize.width *
    Math.min(this.chosenPanel.size.width, this.chosenPanel.size.height);

  const iconGeomtery = new THREE.PlaneBufferGeometry(
    trashDimension,
    trashDimension
  );
  const icon = new THREE.Mesh(iconGeomtery, iconMaterial);
  icon.position.set(
    this.chosenPanel.size.width / 2 - trashDimension / 2,
    this.chosenPanel.size.height / 2 - trashDimension / 2,
    0
  );

  icon.material.depthTest = false;
  icon.renderOrder = RENDERING_ORDER.PANEL_TRASH;
  if (addCounter) icon.renderOrder += this.panelCounter;
  icon.visible = false;

  return icon;
};

export const createPanel = function (areaObject) {
  const panelId =
    this.chosenPanel.size.width > this.chosenPanel.size.height
      ? `${this.chosenPanel.id}_h`
      : this.chosenPanel.id;

  let instancedMesh = areaObject.panelMap.get(panelId);
  if (!instancedMesh) {
    instancedMesh = this.createPanelInstance(areaObject, panelId);
  }

  let plane = this.useInstancedPanel(areaObject, panelId);

  plane.material.depthTest = false;
  plane.renderOrder = RENDERING_ORDER.PANEL + this.panelCounter;

  return plane;
};

export const flatArrayToVectors3D = function (flatArray) {
  const vectorArray = [];
  for (let i = 0; i < flatArray.length; i = i + 3) {
    vectorArray.push(
      new THREE.Vector3(flatArray[i], flatArray[i + 1], flatArray[i + 2])
    );
  }
  return vectorArray;
};

export const createPanelObject = async function (
  areaId,
  point,
  panelId,
  orientation,
  renderOrder
) {
  if (this.sample) return;
  const panelObject = {
    projectId: Number(this.projectId),
    areaId,
    position: point,
    panelId,
    orientation,
    renderOrder,
  };
  return await API.airteam3DViewer.createPanelObject(panelObject);
};

export const createBulkPanelObjects = async function (array) {
  if (this.sample) return;
  return await API.airteam3DViewer.createBulkPanelObjects(array);
};

export const updatePanelObject = async function (
  id,
  areaId,
  point,
  panelId,
  orientation,
  renderOrder
) {
  if (this.sample) return;
  const panelObject = {
    id,
    projectId: Number(this.projectId),
    areaId,
    position: point,
    panelId,
    orientation,
    renderOrder,
  };
  return await API.airteam3DViewer.updatePanelObject(panelObject);
};

export const updateBulkPanelObjects = async function (array) {
  if (this.sample) return;

  return await API.airteam3DViewer.updateBulkPanelObject(array);
};

export const deletePanelObject = async function (id) {
  if (this.sample) return;
  return await API.airteam3DViewer.deleteObject(id);
};

export const deleteBulkPanelObjects = async function (array) {
  if (this.sample) return;
  return await API.airteam3DViewer.deleteBulkPanelObjects(array);
};

export const getDefaultPanelTypes = async function () {
  try {
    const res = await API.airteam3DViewer.getDefaultPanelTypes();
    const defaultPanels = [];

    res.data.forEach((panel) => {
      const originalPanel = {
        ...panel,
        texture: null,
        orientation:
          panel.size.height > panel.size.width ? VERTICAL : HORIZONTAL,
        size: {
          width: panel.size.width / 1000,
          height: panel.size.height / 1000,
        },
      };

      const flippedPanel = {
        ...originalPanel,
        size: {
          height: originalPanel.size.width,
          width: originalPanel.size.height,
        },
        orientation:
          originalPanel.orientation === VERTICAL ? HORIZONTAL : VERTICAL,
      };

      defaultPanels.push(originalPanel, flippedPanel);
    });

    return defaultPanels;
  } catch (error) {
    console.error("Failed to fetch default panels:", error);
  }
};

export const getShiftedCenterPoint = function (points, inPlaneVec, normal) {
  const stepSize = 0.2;

  const vectorPoints = points.map((point) => point.position);

  const leftMostPoint = this.getLeftMostPoint(vectorPoints);
  const bottomMostPoint = this.getBottomMostPoint(vectorPoints);
  const centerPoint = this.getCenterPoint(points);

  const projectedTopLeftPoint = leftMostPoint
    .clone()
    .add(
      inPlaneVec
        .clone()
        .multiplyScalar(
          bottomMostPoint.clone().sub(leftMostPoint).dot(inPlaneVec)
        )
    );

  const pos = centerPoint
    .clone()
    // move the panel vertically
    .add(inPlaneVec.clone().multiplyScalar(stepSize * this.callCount))
    // move the panel horizontally from center Point to topLeft point
    .add(
      projectedTopLeftPoint
        .clone()
        .sub(centerPoint)
        .normalize()
        .multiplyScalar(stepSize * this.callCount)
    )
    // ensure the panel is centered along the horizontal direction
    .sub(
      projectedTopLeftPoint
        .clone()
        .sub(centerPoint)
        .normalize()
        .multiplyScalar(stepSize)
    )
    // ensure the panel is sits on the plane, not just parallel to it
    .sub(normal.clone().multiplyScalar(0));

  this.callCount = this.callCount + 1;

  return pos;
};

export const getShiftedPoint = function (points, inPlaneVec, normal, index, i) {
  const stepSize = 0.1;

  const vectorPoints = points.map((point) => point.position);

  const point = vectorPoints[index];
  const centerPoint = this.getCenterPoint(points);
  // const projectedTopLeftPoint = leftMostPoint
  //   .clone()
  //   .add(
  //     inPlaneVec
  //       .clone()
  //       .multiplyScalar(
  //         bottomMostPoint.clone().sub(leftMostPoint).dot(inPlaneVec)
  //       )
  //   )
  const pos = point
    .clone()
    // move the panel vertically
    // .add(inPlaneVec.clone().multiplyScalar(stepSize * this.callCount))
    // move the panel horizontally from center Point to topLeft point
    .add(
      centerPoint
        .clone()
        .sub(point)
        .normalize()
        .multiplyScalar(0.8 + stepSize * this.callCount + i)
    )
    // ensure the panel is centered along the horizontal direction
    .sub(centerPoint.clone().sub(point).normalize().multiplyScalar(stepSize))
    // ensure the panel is sits on the plane, not just parallel to it
    .sub(normal.clone().multiplyScalar(0));

  return pos;
};

export const onMouseDownFrame = function (event) {
  if (this.disableClick(event)) return;
  if (event.button === 1 || event.button === 2) return;

  let canvasBounds = this.renderer.getContext().canvas.getBoundingClientRect();
  this.mouse.x =
    ((event.clientX - canvasBounds.left) /
      (canvasBounds.right - canvasBounds.left)) *
      2 -
    1;
  this.mouse.y =
    -(
      (event.clientY - canvasBounds.top) /
      (canvasBounds.bottom - canvasBounds.top)
    ) *
      2 +
    1;

  this.raycaster.setFromCamera(this.mouse, this.camera);
  let intersects = this.raycaster.intersectObjects(
    this.selectedArea.panels.map((panel) => panel.plane)
  );
  if (intersects.length > 0) {
    this.startSelection = false;
    return;
  }
  this.unselectPanels();
  this.startSelection = true;
  document.removeEventListener("click", this.selectPanel, false);
  this.startX = event.clientX - canvasBounds.left;
  this.startY = event.clientY - canvasBounds.top;
};

export const onMouseMoveFrame = function (event) {
  event.preventDefault();
  if (this.startSelection) {
    let canvasBounds = this.renderer
      .getContext()
      .canvas.getBoundingClientRect();

    this.currentX = event.clientX - canvasBounds.left;
    this.currentY = event.clientY - canvasBounds.top;

    this.selectionBox.style.left = Math.min(this.startX, this.currentX) + "px";
    this.selectionBox.style.top = Math.min(this.startY, this.currentY) + "px";
    this.selectionBox.style.width =
      Math.abs(this.startX - this.currentX) + "px";
    this.selectionBox.style.height =
      Math.abs(this.startY - this.currentY) + "px";
  }
};

export const onMouseUpFrame = function (event) {
  event.preventDefault();
  this.startSelection = false;
  this.selectObjectsInFrustum();
  this.resetSelectionBox();
  document.addEventListener("click", this.selectPanel, false);
};

export const selectObjectsInFrustum = function () {
  if (
    this.selectionBox.style.width === "0px" &&
    this.selectionBox.style.height === "0px"
  )
    return;
  const projectionMatrix = new THREE.Matrix4();
  projectionMatrix.multiplyMatrices(
    this.camera.projectionMatrix,
    this.camera.matrixWorldInverse
  );

  let canvasBounds = this.renderer.getContext().canvas.getBoundingClientRect();

  const x1 = (this.startX / (canvasBounds.right - canvasBounds.left)) * 2 - 1;
  const y1 = -(this.startY / (canvasBounds.bottom - canvasBounds.top)) * 2 + 1;
  const x2 = (this.currentX / (canvasBounds.right - canvasBounds.left)) * 2 - 1;
  const y2 =
    -(this.currentY / (canvasBounds.bottom - canvasBounds.top)) * 2 + 1;

  const rectLeft = Math.min(x1, x2);
  const rectTop = Math.min(y1, y2);
  const rectRight = Math.max(x1, x2);
  const rectBottom = Math.max(y1, y2);

  const point1 = new THREE.Vector2(rectLeft, rectTop);
  const point2 = new THREE.Vector2(rectRight, rectTop);
  const point3 = new THREE.Vector2(rectRight, rectBottom);
  const point4 = new THREE.Vector2(rectLeft, rectBottom);

  const vs = [point1, point2, point3, point4];

  const frustum = new THREE.Frustum();
  frustum.setFromMatrix(
    new THREE.Matrix4().multiplyMatrices(
      this.camera.projectionMatrix,
      this.camera.matrixWorldInverse
    )
  );

  this.selectedArea.panels.forEach((panel) => {
    const plane = panel.plane;
    const screenPosition = plane.getWorldPosition().project(this.camera);
    if (
      frustum.containsPoint(screenPosition) &&
      this.isInsideConvex(screenPosition, vs)
    ) {
      this.frustumSelection = true;
      panel.selected = true;
      panel.icon.visible = true;
      this.addDragControlsOnPanels();
    }
  });
};

export const resetSelectionBox = function () {
  this.selectionBox.style.left = "0";
  this.selectionBox.style.top = "0";
  this.selectionBox.style.width = "0";
  this.selectionBox.style.height = "0";
};

export const detectPanelDeletion = function (panel) {
  let intersects = this.raycaster.intersectObject(panel.icon);
  if (intersects.length > 0) return true;
  return false;
};

export const addDragControlsOnPanels = function () {
  if (this.selectedArea.panelsDragControls) {
    this.selectedArea.panelsDragControls.dispose();
  }
  this.selectedArea.panelsDragControls = new DragControls(
    this.selectedArea.panels
      .filter((panel) => panel.selected)
      .map((panel) => panel.plane),
    this.camera,
    this.renderer.domElement
  );
  this.selectedArea.panels
    .filter((panel) => panel.selected)
    .map((panel) => panel.plane)
    .forEach((object) => {
      object.userData.lastPosition = new THREE.Vector3().copy(object.position);
    });
  this.selectedArea.panelsDragControls.addEventListener(
    "dragstart",
    this.onDragStart
  );
  this.selectedArea.panelsDragControls.addEventListener("drag", this.onDrag);
  this.selectedArea.panelsDragControls.addEventListener(
    "dragend",
    this.onDragEnd
  );
};

export const onDragStart = function (event) {
  this.selectedArea.panels.forEach(
    (panel) =>
      (panel.plane.prevPosition = {
        x: panel.plane.position.x,
        y: panel.plane.position.y,
        z: panel.plane.position.z,
      })
  );
};

export const onDrag = function (event) {
  const draggedPanel = this.selectedArea.panels.find(
    (panel) => panel.plane.id === event.object.id
  );
  if (!draggedPanel) return;
  if (draggedPanel.selected) {
    this.dragOn = true;
    const dx = event.object.position.x - event.object.userData.lastPosition.x;
    const dy = event.object.position.y - event.object.userData.lastPosition.y;
    const dz = event.object.position.z - event.object.userData.lastPosition.z;

    this.selectedArea.panels
      .filter((panel) => panel.selected)
      .forEach((panel) => {
        const object = panel.plane;
        // move other panels
        if (object !== event.object) {
          object.position.x += dx;
          object.position.y += dy;
          object.position.z += dz;
        }

        // project panels on plane
        var projectedPoint = new THREE.Vector3();
        this.selectedArea.trianglePlane.projectPoint(
          object.position,
          projectedPoint
        );

        object.position.x = projectedPoint.x;
        object.position.y = projectedPoint.y;
        object.position.z = projectedPoint.z;

        object.renderOrder++;
        panel.icon.renderOrder++;
      });

    event.object.userData.lastPosition.copy(event.object.position);
  }
};

export const onDragEnd = async function (e) {
  this.selectedArea.panelsDragControls.removeEventListener(
    "dragstart",
    this.onDragStart
  );
  this.selectedArea.panelsDragControls.removeEventListener("drag", this.onDrag);
  this.selectedArea.panelsDragControls.removeEventListener(
    "dragend",
    this.onDragEnd
  );
  this.removeDragControlsFromPanels();

  const areaVertices =
    this.selectedArea.plane.geometry.attributes.position.array;
  const areaVertices2 = [];
  for (let i = 0; i < areaVertices.length; i = i + 3) {
    areaVertices2.push(
      new THREE.Vector3(
        areaVertices[i],
        areaVertices[i + 1],
        areaVertices[i + 2]
      )
    );
  }
  const deletedPanels = [];
  const updatedPanels = [];

  this.selectedArea.panels
    .filter((panel) => panel.selected)
    .forEach(async (panel) => {
      const plane = panel.plane;

      const geometry = plane.geometry.clone();
      plane.updateMatrix();
      plane.updateWorldMatrix();
      geometry.applyMatrix4(plane.matrixWorld);

      const rectVertices = geometry.attributes.position.array;
      const vertices = [];
      for (let i = 0; i < rectVertices.length; i = i + 3) {
        vertices.push(
          new THREE.Vector3(
            rectVertices[i],
            rectVertices[i + 1],
            rectVertices[i + 2]
          )
        );
      }
      if (
        !this.isRectangleWithinPoints(
          vertices,
          areaVertices2,
          this.selectedArea.indices
        )
      ) {
        deletedPanels.push({ ...panel, deleted: true });
        this.hidePanels([panel], this.selectedArea);
      } else {
        updatedPanels.push({
          id: panel.id,
          areaId: this.selectedArea.id,
          position: {
            x: plane.position.x,
            y: plane.position.y,
            z: plane.position.z,
          },
          panelId: panel.panelId,
          orientation: panel.orientation,
          renderOrder: plane.renderOrder,
          projectId: Number(this.projectId),
          type: "PANEL",
        });
      }
    });

  const updatedPanelsArrays = chunkArray(updatedPanels, 50);
  const updatePanelsPromises = updatedPanelsArrays.map((array) =>
    this.updateBulkPanelObjects(array)
  );
  Promise.all(updatePanelsPromises);

  const filteredPanels = this.selectedArea.panels.filter(
    (panel) => panel.selected
  );
  const movedPanels = deletedPanels.concat(filteredPanels);
  this.undoStack.push({
    action: "MOVE_PANELS",
    panels: movedPanels,
    area: this.selectedArea,
  });
  this.resetRedoStack();
  setTimeout(() => (this.dragOn = false));
  document.addEventListener("click", this.selectPanel, false);
};

export const removeDragControlsFromPanels = function () {
  this.selectedArea.panelsDragControls.deactivate();
};

export const getRightMostPoint = function (points) {
  const screenPoints = points.map((point) =>
    this.toScreenPosition(point.clone(), this.camera)
  );
  let rightMostPoint = screenPoints[0];
  let rightMostPointIndex = 0;
  for (let i = 0; i < screenPoints.length; i++) {
    if (rightMostPoint.x < screenPoints[i].x) {
      rightMostPoint = screenPoints[i];
      rightMostPointIndex = i;
    }
  }
  return points[rightMostPointIndex];
};

export const getLeftMostPoint = function (points) {
  const screenPoints = points.map((point) =>
    this.toScreenPosition(point.clone(), this.camera)
  );
  let leftMostPoint = screenPoints[0];
  let leftMostPointIndex = 0;
  for (let i = 0; i < screenPoints.length; i++) {
    if (leftMostPoint.x > screenPoints[i].x) {
      leftMostPoint = screenPoints[i];
      leftMostPointIndex = i;
    }
  }
  return points[leftMostPointIndex];
};

export const getTopMostPoint = function (points) {
  const screenPoints = points.map((point) =>
    this.toScreenPosition(point.clone(), this.camera)
  );
  let topMostPoint = screenPoints[0];
  let topMostPointIndex = 0;
  for (let i = 0; i < screenPoints.length; i++) {
    if (topMostPoint.y < screenPoints[i].y) {
      topMostPoint = screenPoints[i];
      topMostPointIndex = i;
    }
  }
  return points[topMostPointIndex];
};

export const getBottomMostPoint = function (points) {
  const screenPoints = points.map((point) =>
    this.toScreenPosition(point.clone(), this.camera)
  );
  let bottomMostPoint = screenPoints[0];
  let bottomMostPointIndex = 0;
  for (let i = 0; i < screenPoints.length; i++) {
    if (bottomMostPoint.y > screenPoints[i].y) {
      bottomMostPoint = screenPoints[i];
      bottomMostPointIndex = i;
    }
  }
  return points[bottomMostPointIndex];
};

export const isRectangleWithinPoints = function (
  panelVertices,
  areaVertices,
  indices
) {
  const areaScreenVertices = [];
  const panelScreenVertices = [];
  for (let i = 0; i < areaVertices.length; i++) {
    areaScreenVertices.push(
      this.toScreenPosition(areaVertices[i].clone(), this.camera)
    );
  }
  for (let i = 0; i < panelVertices.length; i++) {
    panelScreenVertices.push(
      this.toScreenPosition(panelVertices[i].clone(), this.camera)
    );
  }
  for (let vertex of panelScreenVertices) {
    if (!this.isInside(vertex, areaScreenVertices, indices)) return false;
  }
  return true;
};

export const toScreenPosition = function (vector, camera) {
  vector.project(camera);

  vector.x = Math.round(
    ((vector.x + 1) * this.renderer.getContext().canvas.width) / 2
  );
  vector.y = Math.round(
    ((-vector.y + 1) * this.renderer.getContext().canvas.height) / 2
  );
  vector.z = 0;
  return {
    x: vector.x,
    y: vector.y,
  };
};

export const isInside = function (point, vs, indices) {
  // Triangulate the polygon
  const triangles = [];
  for (let i = 0; i < indices.length; i++) {
    if (triangles.length > 0 && triangles[triangles.length - 1].length < 3) {
      triangles[triangles.length - 1].push(vs[indices[i]]);
    } else {
      triangles.push([vs[indices[i]]]);
    }
  }

  // Check if the point is inside any of the triangles
  for (const triangle of triangles) {
    const [v1, v2, v3] = triangle;
    const a =
      (1 / 2) *
      (-v2.y * v3.x +
        v1.y * (-v2.x + v3.x) +
        v1.x * (v2.y - v3.y) +
        v2.x * v3.y);
    const sign = a < 0 ? -1 : 1;
    const s =
      (v1.y * v3.x -
        v1.x * v3.y +
        (v3.y - v1.y) * point.x +
        (v1.x - v3.x) * point.y) *
      sign;
    const t =
      (v1.x * v2.y -
        v1.y * v2.x +
        (v1.y - v2.y) * point.x +
        (v2.x - v1.x) * point.y) *
      sign;
    if (s > 0 && t > 0 && s + t < 2 * a * sign) {
      return true;
    }
  }

  return false;
};

export const isInsideConvex = function (point, vs) {
  var x = point.x,
    y = point.y;

  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i].x,
      yi = vs[i].y;
    var xj = vs[j].x,
      yj = vs[j].y;

    var intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

export const undoAddPanel = async function (panel, area) {
  // add action to redo stack
  this.redoStack.push({ action: "ADD_PANEL", panel: panel, area: area });

  const panelObject = this.scene.getObjectById(panel.plane.id);
  area.panels = area.panels.filter((p) => p.plane.id !== panel.plane.id);
  panelObject.visible = false;

  await this.deletePanelObject(panel.id);
};

export const redoAddPanel = async function (panel, area) {
  // add action to undo stack
  this.undoStack.push({ action: "ADD_PANEL", panel: panel, area: area });

  const panelObject = this.scene.getObjectById(panel.plane.id);

  if (!this.anonymousUser) {
    try {
      const { data } = await this.createPanelObject(
        area.id,
        {
          x: panelObject.position.x,
          y: panelObject.position.y,
          z: panelObject.position.z,
        },
        panel.panelId,
        panel.orientation,
        panel.plane.renderOrder
      );
      panel.id = data;
    } catch (e) {}
  }

  area.panels.push(panel);
  panelObject.visible = true;
};

export const undoBulkAddPanels = async function (panels, area) {
  // add action to redo stack
  this.redoStack.push({
    action: "BULK_ADD_PANELS",
    panels: panels,
    area: area,
  });

  const panelPromises = [];

  for (let panel of panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    area.panels = area.panels.filter((p) => p.plane.id !== panel.plane.id);
    panelObject.visible = false;

    panelPromises.push(this.deletePanelObject(panel.id));
  }

  Promise.all(panelPromises);
};

export const redoBulkAddPanels = async function (panels, area) {
  // add action to undo stack
  this.undoStack.push({
    action: "BULK_ADD_PANELS",
    panels: panels,
    area: area,
  });

  const panelPromises = [];

  for (let panel of panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    area.panels.push(panel);
    panelObject.visible = true;

    panelPromises.push(
      this.createPanelObject(
        area.id,
        {
          x: panelObject.position.x,
          y: panelObject.position.y,
          z: panelObject.position.z,
        },
        panel.panelId,
        panel.orientation,
        panel.plane.renderOrder
      )
    );
  }
  Promise.all(panelPromises);
};

export const undoMovePanels = async function (panels, area) {
  for (let panel of panels) {
    const currentPositon = {
      x: panel.plane.position.x,
      y: panel.plane.position.y,
      z: panel.plane.position.z,
    };
    panel.plane.currentPosition = currentPositon;
  }

  for (let panel of panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    panelObject.position.x = panelObject.prevPosition.x;
    panelObject.position.y = panelObject.prevPosition.y;
    panelObject.position.z = panelObject.prevPosition.z;

    panelObject.prevPosition.x = panelObject.currentPosition.x;
    panelObject.prevPosition.y = panelObject.currentPosition.y;
    panelObject.prevPosition.z = panelObject.currentPosition.z;

    panelObject.visible = true;

    await this.updatePanelObject(
      panel.id,
      area.id,
      {
        x: panelObject.position.x,
        y: panelObject.position.y,
        z: panelObject.position.z,
      },
      panel.panelId,
      panel.orientation,
      panelObject.renderOrder
    );

    if (area.panels.find((p) => p.plane.id === panel.plane.id)) {
      let index = area.panels.findIndex((p) => p.plane.id === panel.plane.id);
      area.panels.splice(index, 1, panel);
    } else {
      area.panels.push(panel);
    }
  }

  for (let panel of area.panels) {
    panel.selected = false;
  }
  // add action to redo stack
  this.redoStack.push({
    action: "MOVE_PANELS",
    panels: panels,
    area: area,
  });
};

export const redoMovePanels = async function (panels, area) {
  for (let panel of panels) {
    const currentPositon = {
      x: panel.plane.position.x,
      y: panel.plane.position.y,
      z: panel.plane.position.z,
    };
    panel.plane.currentPosition = currentPositon;
  }
  const oldPanels = [].concat(panels);

  const panelPromises = [];

  for (let panel of panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    panelObject.position.x = panelObject.prevPosition.x;
    panelObject.position.y = panelObject.prevPosition.y;
    panelObject.position.z = panelObject.prevPosition.z;

    panelObject.prevPosition.x = panelObject.currentPosition.x;
    panelObject.prevPosition.y = panelObject.currentPosition.y;
    panelObject.prevPosition.z = panelObject.currentPosition.z;

    if (panel.deleted) {
      panelObject.visible = false;

      panelPromises.push(this.deletePanelObject(panel.id));
    } else {
      panelPromises.push(
        this.updatePanelObject(
          panel.id,
          area.id,
          {
            x: panelObject.position.x,
            y: panelObject.position.y,
            z: panelObject.position.z,
          },
          panel.panelId,
          panel.orientation,
          panelObject.renderOrder
        )
      );
    }
  }

  Promise.all(panelPromises);

  area.panels = area.panels.filter((panel) => !panel.deleted);

  for (let panel of area.panels) {
    panel.selected = false;
  }

  // add action to redo stack
  this.undoStack.push({
    action: "MOVE_PANELS",
    panels: oldPanels,
    area: area,
  });
};

export const undoDeletePanels = async function (panels, area) {
  // add action to redo stack
  this.redoStack.push({
    action: "DELETE_PANELS",
    panels: panels,
    area: area,
  });

  // execute undo action
  for (let panel of panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);

    if (!this.anonymousUser) {
      try {
        const { data } = await this.createPanelObject(
          area.id,
          {
            x: panelObject.position.x,
            y: panelObject.position.y,
            z: panelObject.position.z,
          },
          panel.panelId,
          panel.orientation,
          panel.plane.renderOrder
        );
        panel.id = data;
      } catch (e) {}
    }

    panelObject.visible = true;
  }
  area.panels.push(...panels);
  area.panels.forEach((panel) => (panel.selected = false));
};

export const redoDeletePanels = function (panels, area) {
  // add action to redo stack
  this.undoStack.push({
    action: "DELETE_PANELS",
    panels: panels,
    area: area,
  });

  // execute redo action
  this.hidePanels(panels, area);
  area.panels.forEach((panel) => (panel.selected = false));
};

export const displayPanel = function (panel) {
  let areaObject;
  this.areas.forEach((area) => {
    if (area.id == panel.areaId) {
      areaObject = area;
    }
  });
  if (!areaObject) return;
  const panelPlanes = this.verticalPanelTypes
    .concat(this.horizontalPanelTypes)
    .concat(this.customPanels);
  let panelId;
  panelPlanes.forEach((panelPlane) => {
    // backwards compatibility for old panels without panelId
    if (
      !panel.panelId &&
      panel.size.width == panelPlane.size.width &&
      panel.size.height == panelPlane.size.height
    ) {
      panel.texture = panelPlane.texture;
      panel.orientation = panelPlane.orientation;
      panel.panelId = panelPlane.id;
    } else if (
      panel.panelId &&
      panel.panelId == panelPlane.id &&
      panel.orientation == panelPlane.orientation
    ) {
      panelId = panelPlane.id;
      panel.texture = panelPlane.texture;
      panel.size = {};
      panel.size.height = panelPlane.size.height;
      panel.size.width = panelPlane.size.width;
    }
  });
  if (!panelPlanes.includes(panelId)) {
    panel.texture = this.isPanelVertical(panel)
      ? this.defaultVerticalTexture
      : this.defaultHorizontalTexture;
    panel.orientation == this.getPanelOrientation(panel);
  }
  const points = areaObject?.points.map((point) => point.position);
  const point1 = new THREE.Vector3(
    areaObject?.points[0]?.position?.x,
    areaObject?.points[0]?.position?.y,
    areaObject?.points[0]?.position?.z
  );
  const point2 = new THREE.Vector3(
    areaObject?.points[1]?.position?.x,
    areaObject?.points[1]?.position?.y,
    areaObject?.points[1]?.position?.z
  );
  const point3 = new THREE.Vector3(
    areaObject?.points[2]?.position?.x,
    areaObject?.points[2]?.position?.y,
    areaObject?.points[2]?.position?.z
  );
  // Find the longest edge
  let longestEdge = null;
  let longestEdgeLength = 0;
  for (let i = 0; i < points?.length; i++) {
    const edge = new THREE.Vector3().subVectors(
      points[i],
      points[(i + 1) % points.length]
    );
    const edgeLength = edge.length();
    if (edgeLength > longestEdgeLength) {
      longestEdgeLength = edgeLength;
      longestEdge = edge;
    }
  }
  const normal = new THREE.Vector3();
  normal
    .crossVectors(
      point2.clone().sub(point1.clone()),
      point3.clone().sub(point1)
    )
    .normalize();
  const inPlaneVec = new THREE.Vector3()
    .crossVectors(normal, longestEdge)
    .normalize();
  const planeGeometry = new THREE.PlaneGeometry(
    panel.size.width,
    panel.size.height
  );
  let planeMaterial;
  let needsUpdate = false;
  if (this.texturesLoading || isPanelSmall(panel.size)) {
    planeMaterial = new THREE.MeshBasicMaterial({
      color: BLACK,
      transparent: true,
      opacity: areaObject?.transparencyLevel / 100,
    });
    needsUpdate = true;
  } else {
    planeMaterial = new THREE.MeshBasicMaterial({
      map: panel.texture,
      transparent: true,
      opacity: areaObject?.transparencyLevel / 100,
    });
  }
  const plane = new THREE.Mesh(planeGeometry, planeMaterial);
  plane.material.depthTest = false;
  plane.renderOrder =
    panel.renderOrder || RENDERING_ORDER.PANEL + this.panelCounter;
  const panelPosition = new THREE.Vector3(
    panel?.position?.x,
    panel?.position?.y,
    panel?.position?.z
  );
  const toCamera = new THREE.Vector3()
    .subVectors(this.camera.position, panelPosition)
    .normalize();

  if (normal.dot(toCamera) < 0) {
    normal.negate();
  }

  const offsetDistance = 0.05;
  const offset = normal.clone().multiplyScalar(offsetDistance);
  const offsetPosition = panelPosition.clone().add(offset);

  plane.position.copy(offsetPosition);
  plane.up.copy(inPlaneVec);
  plane.lookAt(offsetPosition.clone().add(normal));
  const geometry = plane.geometry.clone();
  plane.updateMatrix();
  plane.updateWorldMatrix();
  geometry.applyMatrix4(plane.matrixWorld);

  const iconMaterial = new THREE.MeshBasicMaterial({
    map: this.closeTexture,
    transparent: true,
  });

  const trashDimension =
    this.trashSize.width * Math.min(panel.size.width, panel.size.height);

  const iconGeomtery = new THREE.PlaneBufferGeometry(
    trashDimension,
    trashDimension
  );
  const icon = new THREE.Mesh(iconGeomtery, iconMaterial);
  icon.position.set(
    panel.size.width / 2 - trashDimension / 2,
    panel.size.height / 2 - trashDimension / 2,
    0
  );
  icon.material.depthTest = false;
  icon.renderOrder =
    panel.renderOrder + 1 || RENDERING_ORDER.PANEL + this.panelCounter + 1;
  icon.visible = true;
  plane.add(icon);
  this.scene.add(plane);
  const planePanel = {
    id: panel.id,
    plane: plane,
    icon: icon,
    size: {
      width: panel.size.width,
      height: panel.size.height,
    },
    panelId: panel.panelId,
    orientation: panel.orientation,
    placedBefore: true,
  };
  planePanel.icon.visible = false;
  areaObject?.panels.push(planePanel);
  if (needsUpdate) this.panelsToUpdate.push(planePanel);
  this.hideAreaPoints(areaObject);
  this.disablePointDragMode();
  this.isEditButtonDisplayed = areaObject?.panels?.length > 0;
  this.panelCounter++;
};

export const loadPanelTextures = async function () {
  const textureLoader = new THREE.TextureLoader();
  const textureUrls = [];
  for (let i = 0; i < this.horizontalPanelTypes.length; i++) {
    const id = this.horizontalPanelTypes[i].textureId;
    let horizontalTexture = `solar_panel_texture_half_cell_${id}_horizontal.png`;
    textureUrls.push(horizontalTexture, horizontalTexture);
  }

  const customPanelTextureUrl = `solar_panel_texture_half_cell_60_horizontal.png`;
  textureUrls.push(customPanelTextureUrl, customPanelTextureUrl);

  const texturePromises = textureUrls.map(
    (url, index) =>
      new Promise((resolve) => {
        const textureMap = textureLoader.load(
          this.texturePath + url,
          (texture) => {
            if (index % 2 === 0) {
              texture.center = new THREE.Vector2(0.5, 0.5);
              texture.rotation = Math.PI / 2;
              texture.flipY = false;
            }
            resolve(texture);
          }
        );
        if (index < textureUrls.length - 2) {
          if (index % 2 === 0) {
            this.verticalPanelTypes[index / 2].texture = textureMap;
          } else {
            this.horizontalPanelTypes[Math.floor(index / 2)].texture =
              textureMap;
          }
        } else {
          if (index % 2 === 0) {
            this.defaultVerticalTexture = textureMap;
          } else {
            this.defaultHorizontalTexture = textureMap;
          }
          this.customPanels.forEach((panel) => {
            if (index % 2 === 0) {
              if (panel.orientation === VERTICAL) {
                panel.texture = textureMap;
              }
            } else {
              if (panel.orientation === HORIZONTAL) {
                panel.texture = textureMap;
              }
            }
          });
        }
      })
  );

  Promise.all(texturePromises)
    .then(() => {
      this.texturesLoading = false;
      const panelPlanes = this.verticalPanelTypes
        .concat(this.horizontalPanelTypes)
        .concat(this.customPanels);
      this.panelsToUpdate
        .filter((panel) => !isPanelSmall(panel.size))
        .forEach((panel) => {
          panelPlanes.forEach((panelPlane) => {
            // backwards compatibility for old panels without panelId
            if (
              !panel.panelId &&
              panel.size.width == panelPlane.size.width &&
              panel.size.height == panelPlane.size.height
            ) {
              panel.texture = panelPlane.texture;
              panel.orientation = panelPlane.orientation;
              panel.panelId = panelPlane.id;
            } else if (
              panel.panelId &&
              panel.panelId == panelPlane.id &&
              panel.orientation == panelPlane.orientation
            ) {
              panel.texture = panelPlane.texture;
              panel.size = {};
              panel.size.height = panelPlane.size.height;
              panel.size.width = panelPlane.size.width;
            }
          });
          if (!panelPlanes.includes(panel.panelId)) {
            panel.texture = this.isPanelVertical(panel)
              ? this.defaultVerticalTexture
              : this.defaultHorizontalTexture;
            panel.orientation == this.getPanelOrientation(panel);
          }
          const material = panel.plane.material;
          material.color = undefined;
          material.map = panel.texture;
          material.needsUpdate = true;
        });
    })
    .catch((error) => {
      console.error("Error loading textures:", error);
    });
  this.closeTexture = new THREE.TextureLoader().load("/assets/model/trash.png");
};

export const createPanelInstance = function (area, panelId) {
  const rectGeo = new THREE.PlaneGeometry(
    this.chosenPanel.size.width,
    this.chosenPanel.size.height
  );
  let rectMat = null;
  if (isPanelSmall(this.chosenPanel.size)) {
    rectMat = new THREE.MeshBasicMaterial({
      color: BLACK,
      transparent: true,
      opacity: area.transparencyLevel / 100,
    });
  } else {
    rectMat = new THREE.MeshBasicMaterial({
      map: this.chosenPanel.texture,
      transparent: true,
      opacity: area.transparencyLevel / 100,
    });
  }

  let instancedMesh = new THREE.InstancedMesh(rectGeo, rectMat, 100);
  instancedMesh.instanceMatrix.setUsage(THREE.StaticDrawUsage);
  instancedMesh.instanceCount = 0;

  area.panelMap.set(panelId, instancedMesh);
  return instancedMesh;
};

export const useInstancedPanel = function (area, panelId) {
  const instancedMesh = area.panelMap.get(panelId);
  instancedMesh.setMatrixAt(
    instancedMesh.instanceCount,
    area.plane.matrixWorld
  );
  instancedMesh.instanceCount += 1;
  let plane = instancedMesh.clone();
  return plane;
};

export const createTempPanel = function () {
  const geometry = new THREE.PlaneGeometry(
    this.chosenPanel.size.width,
    this.chosenPanel.size.height
  );
  const material = new THREE.MeshBasicMaterial({
    color: BLACK,
  });
  const mesh = new THREE.Mesh(geometry, material);
  return mesh;
};

export const createPanelMesh = function (area) {
  const geometry = new THREE.PlaneGeometry(
    this.chosenPanel.size.width,
    this.chosenPanel.size.height
  );
  let material;
  if (isPanelSmall(this.chosenPanel.size)) {
    material = new THREE.MeshBasicMaterial({
      color: BLACK,
      transparent: true,
      opacity: area.transparencyLevel / 100,
    });
  } else {
    material = new THREE.MeshBasicMaterial({
      map: this.chosenPanel.texture,
      transparent: true,
      opacity: area.transparencyLevel / 100,
    });
  }
  const mesh = new THREE.Mesh(geometry, material);
  return mesh;
};

export const getPanelOrientation = function (panel) {
  if (panel?.size?.width > panel?.size?.height) return HORIZONTAL;
  return VERTICAL;
};

export const isPanelVertical = function (panel) {
  if (this.getPanelOrientation(panel) === VERTICAL) return true;
  else return false;
};

export const chunkArray = function (array, chunkSize) {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunkedArray.push(array.slice(i, i + chunkSize));
  }
  return chunkedArray;
};
